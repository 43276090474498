<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { watch, ref } from 'vue'
import { useMapsStore } from '@/store/maps'
import { useLocationStore } from '@/store/location'
import { useNotificationsStore } from '@/store/notifications'
import { storeToRefs } from 'pinia'
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption
} from '@headlessui/vue'
import TtButton from './TtButton.vue'
import TtContainer from './TtContainer.vue'
import IconParking from '@/components/Icon/ParkingPaid.vue'
import IconTrash from '@/components/Icon/Trash.vue'

import type { Location, Parking } from '@/location'
import TtTurnRadius from './TtTurnRadius.vue'

const { t } = useI18n()

const props = defineProps<{
  location: Location
}>()

const accessibilityOptions = [
  {
    label: '<15',
    value: '15'
  },
  {
    label: '<22',
    value: '22'
  },
  {
    label: '<24',
    value: '24'
  },
  {
    label: '<34',
    value: '34'
  }
]

const { parkings } = storeToRefs(useLocationStore())
const modalOpen = ref(false)
const modalId = ref('')

const newParking = () => {
  let zip = props.location.address.zip.replace(/\s/g, '')
  if (!zip) {
    const addressParts = props.location.name
      .split(',')
      .map((part) => part.trim())
    zip = addressParts[1].split(' ').slice(0, 2).join('')
  }
  let highestNumber = parkings.value.reduce((max, parking) => {
    let number = parseInt(parking.name.replace('#' + zip, ''))
    return number > max ? number : max
  }, 0)
  const name = '#' + zip + (highestNumber + 1)
  parkings.value.push({
    id: 'new-' + parkings.value.length,
    name: name,
    price: 50,
    currency: 'SEK',
    coordinates: {
      lat: props.location.coordinates.lat,
      lng: props.location.coordinates.lng
    },
    parking_bookings: [],
    published: true,
    accessibility: '15'
  })
  useLocationStore().flyToLocation(props.location, 18)
  useMapsStore().map?.toggleParkingSpots(true)
}

const currencySymbol = (currency: string) => {
  const formatter = new Intl.NumberFormat('sv-SE', {
    style: 'currency',
    currency
  })

  let symbol
  formatter.formatToParts(0).forEach(({ type, value }) => {
    if (type === 'currency') {
      symbol = value
    }
  })

  return symbol
}

const openRemoveModal = (id: string) => {
  modalId.value = id
  modalOpen.value = true
}

const removeSpot = async (id: string) => {
  modalOpen.value = false
  modalId.value = ''
  if (id.startsWith('new-')) {
    const index = parkings.value.findIndex((spot) => spot.id === id)
    parkings.value.splice(index, 1)
  } else {
    useNotificationsStore().addNotification(
      'parking-removing',
      t('location.parkingSpotRemoving'),
      'notification',
      false,
      false,
      false,
      false
    )
    const res = await useLocationStore().removeParking(id)
    useNotificationsStore().removeNotification('parking-removing')
    if (res.error) {
      useNotificationsStore().addNotification(
        'parking-removing-error',
        t('location.parkingSpotRemovingError'),
        'error',
        false,
        false,
        false,
        false
      )
      setTimeout(() => {
        useNotificationsStore().removeNotification('parking-removing-error')
      }, 2000)
      return
    }
  }
  useNotificationsStore().addNotification(
    'parking-removed',
    t('location.parkingSpotRemoved'),
    'notification',
    false,
    false,
    false,
    false
  )
  useMapsStore().map?.toggleParkingSpots(true)
  setTimeout(() => {
    useNotificationsStore().removeNotification('parking-removed')
  }, 1000)
}
</script>

<template>
  <Disclosure
    v-for="spot in parkings"
    as="div"
    v-slot="{ open, close }"
    class="mb-2"
  >
    <DisclosureButton
      as="button"
      class="flex h-[52px] w-full items-center justify-between rounded border border-tt-gray/25 p-4"
    >
      <div class="flex items-center">
        <IconParking />
        <span class="mx-2">{{ spot.name }}</span>
        <div
          class="h-2 w-2 rounded-full"
          :class="spot.published ? 'bg-tt-green' : 'bg-tt-red'"
        ></div>
      </div>
      <div class="flex items-center font-mono">
        <span>{{ spot.price }}</span>
        <span class="ml-1 mr-2 text-tt-gray/50">{{
          currencySymbol(spot.currency)
        }}</span>
        <svg
          :class="!open && 'rotate-180'"
          width="11"
          height="6"
          viewBox="0 0 11 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M.688 4.812c0-.176.067-.352.2-.486L5.015.201a.687.687 0 0 1 .972 0l4.125 4.125a.687.687 0 1 1-.972.973L5.5 1.659 1.86 5.3a.687.687 0 0 1-1.173-.487Z"
            fill="currentColor"
          />
        </svg>
      </div>
    </DisclosureButton>
    <DisclosurePanel v-show="open">
      <div class="mt-2 flex flex-col space-y-2 pl-4">
        <div
          class="flex h-[52px] items-center justify-between rounded border border-tt-gray/25 p-4"
          @click="spot.published = !spot.published"
        >
          <span>{{ t('location.parkingPublished') }}</span>
          <input
            v-model="spot.published"
            style="filter: invert(1)"
            class="peer form-checkbox h-4.5 w-4.5 cursor-pointer rounded border border-[#201f1f] bg-transparent text-[#FD1E5A] transition-colors focus:border-[#FD1E5A] focus:outline-none focus:ring-0"
            type="checkbox"
          />
        </div>
        <div
          class="flex h-[52px] items-center justify-between rounded border border-tt-gray/25 p-4"
        >
          <span>{{ t('location.parkingAccessibility') }}</span>
          <div class="relative flex w-[100px] justify-end font-mono">
            <Listbox v-model="spot.accessibility">
              <ListboxButton as="button">
                <span>&lt;{{ spot.accessibility }}</span>
                <span class="mx-1 text-tt-gray/50">m</span>
                <TtTurnRadius
                  :radius="Number(spot.accessibility)"
                  :hideLabel="true"
                  :inline="true"
                />
                <svg
                  class="ml-3 inline rotate-180"
                  width="11"
                  height="6"
                  viewBox="0 0 11 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M.688 4.812c0-.176.067-.352.2-.486L5.015.201a.687.687 0 0 1 .972 0l4.125 4.125a.687.687 0 1 1-.972.973L5.5 1.659 1.86 5.3a.687.687 0 0 1-1.173-.487Z"
                    fill="currentColor"
                  />
                </svg>
              </ListboxButton>
              <ListboxOptions
                class="absolute -right-4 z-20 mt-8 w-full overflow-auto rounded bg-white shadow-sidebar outline-none"
              >
                <ListboxOption
                  v-for="option in accessibilityOptions"
                  v-slot="{ active }"
                  :key="option.value"
                  :value="option.value"
                >
                  <button
                    :class="`flex w-full items-center justify-between p-2 text-left ${
                      active && 'ring-1 ring-inset ring-tt-green'
                    }`"
                  >
                    <span
                      >{{ option.label }}
                      <span class="mr-1 text-tt-gray/50">m</span>
                      <TtTurnRadius
                        :radius="Number(option.value)"
                        :hideLabel="true"
                        :inline="true"
                    /></span>
                    <span v-if="active">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="11"
                        height="8"
                        fill="none"
                      >
                        <path
                          fill="currentColor"
                          d="M9.101.47a.47.47 0 1 1 .941 0v3.296c0 .953-.772 1.725-1.726 1.725H1.607l1.706 1.707a.47.47 0 0 1-.665.665L.139 5.353a.469.469 0 0 1 0-.665l2.51-2.512a.47.47 0 0 1 .665.665L1.607 4.548h6.71a.784.784 0 0 0 .784-.784V.47Z"
                        />
                      </svg>
                    </span>
                  </button>
                </ListboxOption>
              </ListboxOptions>
            </Listbox>
          </div>
        </div>
        <div
          class="flex h-[52px] items-center justify-between rounded border border-tt-gray/25 p-4"
        >
          <span>{{ t('location.parkingPrice') }}</span>
          <div class="font-mono">
            <input
              type="number"
              v-model="spot.price"
              class="mr-1 w-20 border-none p-0 text-right text-base focus:border-tt-green focus:outline-none focus:ring-0 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
            />
            <span class="text-tt-gray/50">{{
              currencySymbol(spot.currency)
            }}</span>
          </div>
        </div>
        <div
          class="flex h-[52px] cursor-pointer items-center justify-between rounded border border-tt-gray/25 p-4"
          @click="openRemoveModal(spot.id)"
        >
          <span>{{ t('location.parkingRemove') }}</span>
          <IconTrash class="h-6 w-6" />
        </div>
      </div>
    </DisclosurePanel>
  </Disclosure>
  <TtButton
    :as="'button'"
    :size="'large'"
    :type="'tertiary'"
    class="mt-8 !flex-grow px-8 tracking-tighter"
    @click="newParking"
  >
    {{ t('location.addParkingSpot') }}
  </TtButton>
  <TtContainer
    @set-open="(val) => (modalOpen = val)"
    variant="popup-card-bottom"
    :open="modalOpen"
  >
    <template #header>
      <div class="relative grid w-full grid-cols-10 px-5 pb-2 pt-5">
        <h2
          class="col-span-9 text-2xl font-semibold leading-7 tracking-tight text-black"
        >
          {{ t('location.removeReviewConfirmation') }}
        </h2>
        <button @click="modalOpen = false">
          <IconClose class="ml-auto mr-0 h-3.5 w-3.5 text-tt-gray" />
        </button>
      </div>
      <p class="px-5 text-lg font-light tracking-tight">
        {{ t('location.noGoingBack') }}
      </p>
    </template>
    <div class="overflow-auto px-5 pb-8">
      <div class="mt-7 grid gap-4">
        <TtButton
          @click="removeSpot(modalId)"
          size="large"
          type="danger"
          as="button"
        >
          <IconTrash class="mr-1" />
          {{ t('location.removeParking') }}
        </TtButton>
        <TtButton
          @click="modalOpen = false"
          size="large"
          type="tertiary"
          as="button"
        >
          {{ t('location.cancel') }}
        </TtButton>
      </div>
    </div>
  </TtContainer>
</template>
