import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import RouteView from '../views/RouteView.vue'
import NewRouteView from '../views/NewRouteView.vue'
import ProfileView from '../views/ProfileView.vue'
import ProfileEditView from '../views/ProfileEditView.vue'
import ProfileBookingsView from '../views/ProfileBookingsView.vue'
import LocationView from '../views/LocationView.vue'
import { useAccountStore } from '@/store/account'
import { hide } from '@popperjs/core'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    // {
    //   path: '/403',
    //   name: '403',
    //   component: () => import('../views/errors/ErrorPage403.vue'),
    // },
    // {
    //   path: '/404',
    //   name: '404',
    //   component: () => import('../views/errors/ErrorPage404.vue'),
    // },
    // {
    //   path: '/500',
    //   name: '500',
    //   component: () => import('../views/errors/ErrorPage500.vue'),
    // },
    {
      path: '/',
      name: 'home',
      component: HomeView
    },
    {
      path: '/route',
      name: 'route',
      component: RouteView
    },
    {
      path: '/route/new',
      name: 'new-route',
      component: NewRouteView
    },
    {
      path: '/location/:id?',
      name: 'location',
      component: LocationView
    },
    {
      path: '/location/:id/edit',
      name: 'edit-location',
      component: LocationView
    },
    {
      path: '/profile',
      name: 'profile',
      component: ProfileView
    },
    {
      path: '/profile/edit',
      name: 'edit-profile',
      component: ProfileEditView
    },
    {
      path: '/profile/bookings',
      name: 'bookings',
      component: ProfileBookingsView
    },
    {
      path: '/products',
      name: 'products',
      component: () => import('@/views/ProductsView.vue')
    },
    {
      path: '/purchase/:id?',
      name: 'purchase',
      component: () => import('@/views/PurchaseView.vue')
    },
    {
      path: '/confirm-purchase/:id?', // The checkout session ID
      name: 'purchase-success',
      component: () => import('@/views/PurchaseSuccessView.vue')
    },
    {
      path: '/profile/2fa',
      name: 'profile-2fa',
      component: () => import('@/views/Profile/2FAEnrollment.vue'),
      meta: {
        isFullscreen: true
      }
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/views/Login/RegisterView.vue'),
      meta: {
        public: true
      }
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login/LoginView.vue'),
      meta: {
        public: true
      }
    },
    {
      path: '/login/2fa',
      name: '2fa',
      component: () => import('@/views/Login/2FaView.vue'),
      meta: {
        public: true
      },
      beforeEnter: (to, from, next) => {
        const accountStore = useAccountStore()
        const { login_form } = accountStore
        if (!login_form.email || !login_form.password) {
          return next({ name: 'login' })
        }
        next()
      }
    },
    {
      path: '/login/bankid',
      name: 'bankid',
      component: () => import('@/views/Login/BankIDView.vue'),
      meta: {
        public: true
      }
      // beforeEnter: (to, from, next) => {
      //   const accountStore = useAccountStore()
      //   const { login_form } = accountStore
      //   if (!login_form.email || !login_form.password) {
      //     return next({ name: 'login' })
      //   }
      //   next()
      // }
    },
    {
      path: '/profile/verify',
      name: 'verify-profile',
      component: () => import('@/views/Profile/VerifyProfile.vue'),
      meta: {
        isFullscreen: true
      }
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: () => import('@/views/Login/ForgotPasswordView.vue'),
      meta: {
        public: true
      }
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      component: () => import('@/views/Login/ResetPasswordView.vue'),
      meta: {
        public: true
      }
    },
    {
      path: '/company-test',
      name: 'company-test',
      component: () => import('@/views/CompanyTest.vue'),
      meta: {
        public: true
      }
    }
  ]
})

router.beforeEach(async (to, from, next) => {
  const accountStore = useAccountStore()
  const { verify } = accountStore

  if (!to.meta.public && (await verify()) === false) {
    return next({ name: 'login' })
  }

  next()
})

export default router
