<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useAccountStore } from '@/store/account'
import userPosition from '@/native/geolocation'
const { firebase_id } = storeToRefs(useAccountStore())


const formatNumber = (number: number, digits: number = 2) => {
  return number.toLocaleString('en-US', {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits
  })
}

const copyToCliboard = () => {
  navigator.clipboard.writeText(firebase_id.value)
}

</script>

<template>
  <div className="pt-safe-top">
    <div v-if="userPosition.position.value">
      <p>Latitude: {{ formatNumber(userPosition.position.value.coords.latitude, 7) }}</p>
      <p>Longitude: {{ formatNumber(userPosition.position.value.coords.longitude, 7) }}</p>
      <p>Heading: {{ formatNumber(userPosition.rotation.value) }}</p>
      <p @click="copyToCliboard">Firebase ID: {{ firebase_id }}</p>
      <p>
        <ul>
          <li v-for="(value, key) in userPosition.debugging.value">
            <div>{{ key }}</div>
            <div v-if="typeof value === 'number'">{{ formatNumber(value) }}</div>
            <ul v-else-if="typeof value === 'object'" v-for="(v, key) in value">
              <li>{{ key }}:
                <template v-if="typeof v === 'number'">{{ formatNumber(v) }}</template>
                <template v-else>{{ v }}</template>
              </li>
            </ul>
            <div v-else>{{ value }}</div>
          </li>
        </ul>
      </p>
      <p>Distance moved: {{ formatNumber(userPosition.accDistance.value) }}</p>
    </div>
  </div>
</template>
