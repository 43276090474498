import { PushNotifications } from '@capacitor/push-notifications'
import { useAccountStore } from '@/store/account'

const { setFirebaseID } = useAccountStore()

const addListeners = async () => {
  const promises = []
  promises.push(
    PushNotifications.addListener('registration', (token) => {
      setFirebaseID(token.value)
      console.info('Registration token: ', token.value)
    })
  )

  promises.push(
    PushNotifications.addListener('registrationError', (err) => {
      console.error('Registration error: ', err.error)
    })
  )

  promises.push(
    PushNotifications.addListener(
      'pushNotificationReceived',
      (notification) => {
        console.log('Push notification received: ', notification)
      }
    )
  )

  promises.push(
    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification) => {
        console.log(
          'Push notification action performed',
          notification.actionId,
          notification.inputValue
        )
      }
    )
  )

  await Promise.all(promises)
}

const checkPermissions = async () => {
  let permStatus = await PushNotifications.checkPermissions()

  while (permStatus.receive === 'prompt') {
    await new Promise((resolve) => setTimeout(resolve, 1000)) // Poll in case another prompt is in progress
    permStatus = await PushNotifications.requestPermissions()
  }

  if (permStatus.receive !== 'granted') {
    throw new Error('User denied permissions!')
  }

  return permStatus.receive === 'granted'
}

const registerNotifications = async () => {
  try {
    await checkPermissions()
  } catch (error) {
    console.error(error)
    return
  }

  await PushNotifications.register()
}

const getDeliveredNotifications = async () => {
  try {
    await checkPermissions()
  } catch (error) {
    console.error(error)
    return
  }

  const notificationList = await PushNotifications.getDeliveredNotifications()
  console.log('delivered notifications', notificationList)
}

export { addListeners, registerNotifications, getDeliveredNotifications }
