<script setup lang="ts">
import { computed, ref } from 'vue'
import TtContainer from './TtContainer.vue'
import TtPhoneInput from './TtPhoneInput.vue'
import TtButton from './TtButton.vue'
import TtAccordion from './TtAccordion.vue'
import TtMetaTile from './TtMetaTile.vue'
import IconParking from './Icon/ParkingPaid.vue'
import IconClose from './Icon/Close.vue'
import type { Location, MetaValue, Parking } from '@/location'
import { useLocationStore } from '@/store/location'

const { flyToLocation, contactJour } = useLocationStore()
const props = defineProps<{
  location: Location
  parking: Parking
  open: boolean
}>()

const emit = defineEmits(['close'])

const phoneNumber = ref('')

const locationImageSrc = computed(() => {
  return `https://api.mapbox.com/styles/v1/mapbox/satellite-v9/static/${
    props.parking.coordinates.lng
  },${props.parking.coordinates.lat},18,0/600x400?access_token=${
    import.meta.env.VITE_MAPBOX_ACCESS_TOKEN
  }`
})

const checkInText = computed(() => {
  const meta = props.location.meta.find((meta) => meta.type === 'checkin')
  if (meta) {
    const metaValue = meta.value as MetaValue
    return metaValue.key
  }
  return ''
})

const comfortMeta = computed(() => {
  const comforts = props.location.meta.filter(
    (item: any) => item.type === 'comfort'
  )
  return comforts.reduce((acc: any, curr: any) => {
    const { type, value, assets } = curr
    value.assets = assets
    if (!acc[type]) {
      acc[type] = { type, value: [value] }
    } else {
      if (!acc[type].value.includes(value)) {
        acc[type].value.push(value)
      }
    }
    return acc
  }, {}).comfort?.value
})

const goToLocation = () => {
  flyToLocation(props.location, 16)
  emit('close')
}

const contactLocationJour = async () => {
  if (props.location?.id) {
    const res = await contactJour(props.location?.id, phoneNumber.value)
    if (res?.message) {
      alert(res?.message)
    }
    if (res?.success) {
      phoneNumber.value = ''
    }
  }
}
</script>

<template>
  <TtContainer
    @set-open="emit('close')"
    variant="popup-card-offset"
    :open="open"
    class="z-50"
  >
    <template #header>
      <button
        class="absolute right-5 top-5 rounded-full bg-white/70 p-3 backdrop-blur-sm"
        @click="emit('close')"
      >
        <IconClose class="h-3.5 w-3.5 text-tt-gray" />
      </button>
    </template>
    <div class="overflow-auto px-5 pb-8">
      <div class="mt-14 flex items-center justify-center gap-2">
        <IconParking class="h-6 w-6" />
        <div class="text-3xl font-semibold tracking-tight">
          {{ parking.name }}
        </div>
      </div>
      <div class="text-center text-sm font-light">
        Lat {{ parking.coordinates.lat }}, Long {{ parking.coordinates.lng }}
      </div>
      <div class="mt-8 text-center">
        <h2 class="text-3xl font-semibold tracking-tight">
          Bokningsbekräftelse
        </h2>
        <p class="mt-4 text-lg font-light leading-7 tracking-tight">
          Vi har mottagit din bokning av uppställningsplats
          {{ parking.name }} på {{ location.address.street }} i
          {{ location.address.city }}. Ni kommer automatisk checkas in när ni
          anländer.
        </p>
      </div>
      <div class="mt-3">
        <img :src="locationImageSrc" alt="" class="block w-full" />
      </div>
      <div class="mt-5 grid gap-2">
        <TtButton type="primary" size="large" as="button" @click="goToLocation">
          Visa plats på karta
        </TtButton>
        <TtButton type="tertiary" size="large" as="button">
          Checka in
        </TtButton>
      </div>
      <div class="mb-8 mt-9 border-b border-black/10 pb-8">
        <h3 class="text-lg font-medium">Anvisningar</h3>
        <p class="mt-1 text-lg font-light leading-7 tracking-tight">
          <template v-if="checkInText">
            {{ checkInText }}
          </template>
          <template v-else>
            <!-- DEMO example -->
            Vid grind finns en QR-läsare, scanna ditt profilkort så öppnas
            grinden. Uppställningsplatserna är markerade med skyltar på stolpar,
            kontrollera mot GPS-kordinater att du står på rätt plats och inom
            markering.
          </template>
        </p>
      </div>
      <div class="mb-8 mt-8 border-b border-black/10 pb-8">
        <TtAccordion>
          <template #accordionButton>
            <span class="text-lg font-semibold">Bekvämligheter</span>
          </template>
          <div class="mt-3 grid gap-1.5">
            <template v-if="location.meta?.length">
              <TtMetaTile
                v-for="(item, index) in comfortMeta"
                :key="index"
                :item="item"
                type="meta"
              />
            </template>
            <template v-else>
              <!-- DEMO example -->
              <TtMetaTile
                :item="{ name: 'Toalett', key: 'toilet' }"
                type="meta"
              />
              <TtMetaTile :item="{ name: 'Dusch', key: 'dusch' }" type="meta" />
            </template>
          </div>
        </TtAccordion>
      </div>
      <div class="mt-12">
        <div class="text-center font-medium">
          <a class="text-tt-red underline" href="">
            Vid brådskande och allvarliga fel, kontakta jour
          </a>
        </div>
        <p
          class="mx-auto mt-2 max-w-[40ch] text-center font-light leading-[1.75]"
        >
          Fyll i ditt telefonnummer så kommer utsedd jourhavande för platsen att
          ta kontakt med er.
        </p>
        <TtPhoneInput v-model="phoneNumber" />
        <TtButton type="danger" size="large" as="button" class="mt-2" @click="contactLocationJour">
          Kom i kontakt med jour
        </TtButton>
      </div>
    </div>
  </TtContainer>
</template>
