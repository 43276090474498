<script setup lang="ts">
import { computed, onUnmounted } from 'vue'
import type { Location } from '@/location'
import { useSidebarStore } from '@/store/sidebars'
import router from '@/router'
import { removeTTComponent } from '@/ttmap/functions'

import Icon from '@/components/Icon.vue'
import IconVerified from '@/components/Icon/Verified.vue'
import IconStar from '@/components/Icon/Star.vue'
import { has } from 'lodash'

onUnmounted(() => {
  removeTTComponent('tt-location-popup')
})

const sidebar = useSidebarStore()

const props = defineProps<Location>()

const goToLocation = () => {
  router.push({ name: 'location', params: { id: props.id } })
  if (!sidebar.open) sidebar.toggleSidebar()
  removeTTComponent('tt-location-popup')
}

const verified = computed(() => {
  return props.meta?.some(
    (meta) => meta.type === 'trucktrust_verified' && meta.value === 'true'
  )
})

const formattedAddress = computed(() => {
  if (!props.address) return ''
  return `${props.address.street}${
    props.address.city ? ', ' + props.address.city : ''
  }`
})

const locationImageSrc = computed(() => {
  if (!props.coordinates) return ''
  return `https://api.mapbox.com/styles/v1/mapbox/satellite-v9/static/${
    props.coordinates.lng
  },${props.coordinates.lat},16,0/300x200?access_token=${
    import.meta.env.VITE_MAPBOX_ACCESS_TOKEN
  }`
})

let isOpen = false

const todayHours = computed(() => {
  const openHoursMeta = props.meta.find((item) => item.type === 'openhours')
  const data =
    openHoursMeta && typeof openHoursMeta.value !== 'string'
      ? JSON.parse(openHoursMeta.value.key)
      : {}

  if (!openHoursMeta) {
    return
  }

  if (data.allweek && data.allweek.allday) {
    isOpen = true
    return 'Öppet alla dagar'
  } else if (data.allweek) {
    isOpen = true
    return (
      'Öppet alla dagar ' + data.allweek.opens + ' - ' + data.allweek.closes
    )
  }

  const currentDay = new Date().getDay()
  const currentTime = new Date().getHours() + ':' + new Date().getMinutes()

  const days = [
    { key: 'sunday', label: 'söndag' },
    { key: 'monday', label: 'måndag' },
    { key: 'tuesday', label: 'tisdag' },
    { key: 'wednesday', label: 'onsdag' },
    { key: 'thursday', label: 'torsdag' },
    { key: 'friday', label: 'fredag' },
    { key: 'saturday', label: 'lördag' }
  ]

  const getDayData = (dayIndex: number) => data[days[dayIndex].key]

  if (getDayData(currentDay) && getDayData(currentDay).allday) {
    isOpen = true
    return 'Öppet idag'
  } else if (
    getDayData(currentDay) &&
    getDayData(currentDay).closes > currentTime
  ) {
    isOpen = true
    return (
      'Öppet idag ' +
      getDayData(currentDay).opens +
      ' - ' +
      getDayData(currentDay).closes
    )
  } else if (
    getDayData(currentDay) &&
    getDayData(currentDay).closes < currentTime
  ) {
    isOpen = false
    for (let i = 1; i < 8; i++) {
      const nextDay = (currentDay + i) % 7
      if (getDayData(nextDay) && getDayData(nextDay).allday) {
        return 'Öppnar igen ' + days[nextDay].label
      } else if (getDayData(nextDay)) {
        return (
          'Öppnar igen ' + days[nextDay].label + ' ' + getDayData(nextDay).opens
        )
      }
    }
  } else if (!getDayData(currentDay)) {
    isOpen = false
    for (let i = 1; i < 8; i++) {
      const nextDay = (currentDay + i) % 7
      if (getDayData(nextDay) && getDayData(nextDay).allday) {
        return 'Öppnar igen ' + days[nextDay].label
      } else if (getDayData(nextDay)) {
        return (
          'Öppnar igen ' + days[nextDay].label + ' ' + getDayData(nextDay).opens
        )
      }
    }
  }
  return 'Stängt'
})
</script>

<template>
  <button
    @click.prevent="goToLocation"
    class="overflow-hidden rounded-md text-left"
  >
    <div class="relative h-[180px] w-[267px] overflow-hidden rounded-md">
      <div
        class="absolute left-0 top-0 h-full w-full bg-gradient-to-b from-transparent from-30% to-black/50 to-100%"
      ></div>
      <p
        v-if="todayHours"
        :class="`${
          isOpen ? 'bg-tt-green' : 'bg-tt-yellow'
        } px-2 py-0.5 text-center text-sm`"
      >
        {{ todayHours }}
      </p>
      <!-- <div
        v-if="verified"
        class="absolute left-2 top-2 flex items-center gap-2"
      >
        <IconVerified />
        <span class="font-sans text-base font-semibold text-white"
          >Verified</span
        >
      </div> -->
      <div class="absolute bottom-2 left-7 flex items-center gap-2">
        <IconStar />
        <span class="font-sans text-base text-white">
          <span class="font-medium">{{ rating ?? 0 }}</span>
          <template v-if="review_count">
            <span class="px-1">&bull;</span>
            <span class="font-light">{{ review_count ?? 0 }} omdömen</span>
          </template>
        </span>
      </div>
      <img
        class="location-img h-[108%] w-full bg-tt-gray object-cover"
        :src="locationImageSrc"
        :alt="formattedAddress"
      />
    </div>
    <div class="px-6 pb-4 pt-5">
      <h3
        class="mb-1 w-[219px] overflow-hidden truncate font-sans text-xl font-semibold leading-snug tracking-tight text-tt-gray"
      >
        {{ formattedAddress }}
      </h3>
      <div class="flex items-center gap-1">
        <Icon
          v-if="props.assets"
          :location="props"
          :size="6"
          :priority="['icon-card']"
        />
        <p
          v-if="name"
          class="w-[195px] overflow-hidden truncate font-sans text-base font-light leading-tight tracking-tighter text-tt-gray"
        >
          {{ name }}
        </p>
      </div>
    </div>
  </button>
</template>
