<script setup lang="ts">
import type { Location } from '@/location'
import type { Asset } from '@/location'

import { computed } from 'vue'

const icon = computed(() => {
  const assets = props.assets || props.location?.assets

  // Add default icon regex
  const priority = [...(props.priority || []), 'icon.*']

  let icon = null
  for (const assetType of priority) {
    const re = new RegExp(assetType)
    icon = assets?.find((asset) => re.test(asset.type))
    if (icon) break
  }

  return icon
})

const src = computed(() => {
  if (!icon.value) {
    console.warn(`No icon found for ${props.type}`)
    return 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==' // 1x1 transparent gif
  }

  return `${import.meta.env.VITE_GATEWAY_URL.replace('/v1', '')}/assets/${
    icon.value.type
  }/${icon.value.name}${icon.value.ext}`
})

const initials = computed(() => {
  let ico = icon.value
  if (!ico) {
    const assets = props.assets || props.location?.assets
    ico = assets?.find((a: any) => a?.type === 'label')
  }
  if (ico?.type !== 'label') {
    return ''
  }
  const allNames = ico.name.trim().split(/( |\/)/g);
  const initials = allNames.reduce((acc, curr, index) => {
    if(index === 0 || index === allNames.length - 1){
      acc = `${acc}${curr.charAt(0).toUpperCase()}`;
    }
    return acc;
  }, '');
  return initials.length > 1 ? initials.substring(0, 3) : ico.name.trim().toUpperCase().substring(0, 2);
})

const props = withDefaults(
  defineProps<{
    location?: Location // Optional as you can also pass in assets directly
    assets?: Asset[] // Optional as these can also be passed in via location
    size?: number
    priority?: string[] // as regex strings
  }>(),
  {
    size: 6,
    type: []
  }
)
</script>

<template>
  <div v-if="initials" :class="['flex justify-center items-center overflow-hidden', `w-${props.size} h-${props.size}`]">{{ initials }}</div>
  <img v-else :src="src" :class="[`w-${props.size} h-${props.size}`]" />
</template>
