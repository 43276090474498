import { registerPlugin } from '@capacitor/core'

export interface LocationSettingsPlugin {
  // Will check if location services are enabled
  // If they are not, it will prompt the user to enable them
  checkLocationSettings(): Promise<{ isLocationEnabled: boolean }>
}

const LocationSettings =
  registerPlugin<LocationSettingsPlugin>('LocationSettings')

export { LocationSettings }
