<script setup lang="ts">
import TtContainer from '@/components/TtContainer.vue'
import ProfilePicture from '@/components/Icon/ProfilePicture.vue'
import IconClose from '@/components/Icon/Close.vue'
import LogoFullLight from '@/components/Logo/LogoFullLight.vue'
import ProfileSubHead from '@/components/ProfileSubHead.vue'
import TtButton from '@/components/TtButton.vue'

import { storeToRefs } from 'pinia'
import { useAccountStore } from '@/store/account'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { ScreenBrightness } from '@capacitor-community/screen-brightness'
import { ref, onMounted } from 'vue'
import { onBeforeUnmount } from 'vue'
import axios from '@/axios'
import { isNative } from '@/helpers'
import VersionText from '@/components/VersionText.vue'
import { useDebugStore } from '@/store/debug'

const router = useRouter()

const { t } = useI18n({ useScope: 'global' })

const { user } = storeToRefs(useAccountStore())
const debug = useDebugStore()

const systemBrightness = ref(1.0)

const getSystemBrightness = async () => {
  const { brightness } = await ScreenBrightness.getBrightness()
  systemBrightness.value = brightness
}

onMounted(async () => {
  if (isNative) {
    await getSystemBrightness()
    await ScreenBrightness.setBrightness({ brightness: 1.0 })
  }
})

onBeforeUnmount(async () => {
  if (isNative) {
    await ScreenBrightness.setBrightness({ brightness: systemBrightness.value })
  }
})

const notImplemented = () => {
  alert('Not implemented, contact support for more information.')
}

const instance = axios.create({
  baseURL: import.meta.env.VITE_GATEWAY_URL + '/billing'
})

</script>

<template>
  <div>
    <TtContainer
      @set-open="
        (val) => {
          val === false && router.push('/')
        }
      "
      :open="true"
      variant="popup-card-offset"
      class="z-40"
    >
      <template #offset>
        <div>
          <LogoFullLight class="mx-auto h-6" />
          <button class="absolute right-6 top-1" @click="router.push('/')">
            <IconClose class="h-3.5 w-3.5 text-white" />
          </button>
        </div>
      </template>
      <template #header>
        <div class="-mt-[4.75rem]">
          <ProfilePicture
            size="141x141"
            class="mx-auto block h-[141px] w-[141px] rounded-full object-cover"
          />
        </div>
        <div
          aria-hidden="true"
          class="absolute -bottom-6 left-0 z-10 block w-full px-1.5 pb-4 pt-2"
        >
          <div
            class="absolute inset-0 z-0 backdrop-blur-[1.5px] [mask-image:linear-gradient(white_70%,transparent)]"
          ></div>
          <div
            class="absolute inset-0 z-0 bg-gradient-to-b from-white from-40% to-[transparent]"
          ></div>
        </div>
      </template>
      <div class="mt-6 px-5 pb-10">
        <ProfileSubHead />
        <div class="mt-10 flex justify-center">
          <img
            src="/dummy-qr.png"
            width="194"
            height="194"
            alt=""
            loading="lazy"
            class="block"
          />
        </div>
        <div class="mt-9 text-center font-mono tracking-tight text-tt-gray">
          3839c9ce-0a14-ca0f649c833f
        </div>
        <div class="mt-12 grid gap-3">
          <TtButton
            :type="'primary'"
            :size="'large'"
            as="router-link"
            :to="{
              name: 'edit-profile'
            }"
          >
            {{ t('profile.updateProfile') }}
          </TtButton>
          <TtButton
            :type="'tertiary'"
            :size="'large'"
            as="router-link"
            :to="{
              name: 'bookings'
            }"
          >
            {{ t('profile.mybookings') }}
          </TtButton>
          <TtButton
            :type="'tertiary'"
            :size="'large'"
            as="router-link"
            to="/login"
          >
            {{ t('profile.logout') }}
          </TtButton>
          <div v-if="user?.id" class="mt-8 text-center">
            <button
              class="font-medium text-tt-red underline"
              @click="notImplemented()"
            >
              {{ t('profile.terminateAccount') }}
            </button>
          </div>
          <div class="mt-4 text-center">
            <button @click="debug.increment"><VersionText /></button>
          </div>
        </div>
      </div>
    </TtContainer>
  </div>
</template>
