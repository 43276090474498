import type TTMap from '@/ttmap'
import mapboxgl from 'mapbox-gl'
import pinia from '@/store/store'
import { useLocationStore } from '@/store/location'
import router from '@/router'
import { eachDayOfInterval } from 'date-fns/esm'

const location = useLocationStore(pinia)

export default class PinMarker {
  map: TTMap
  marker: mapboxgl.Marker | undefined
  timeout: number | undefined
  active: boolean = false
  lngLat: mapboxgl.LngLat | undefined
  longClickTimeout: number | undefined

  constructor(map: TTMap) {
    this.map = map

    this.onLongClick()
    this.map.on('mousedown', (e) => {
      const target = e.originalEvent?.target as HTMLElement
      if (!target?.classList.contains('mapboxgl-canvas')) {
        return
      }
      if (this.active && !this.map.isDrawing) {
        this.toggle(e.lngLat)
      }
    })

    this.map.on('zoomstart', (e) => {
      if (this.timeout) {
        clearTimeout(this.timeout)
        this.timeout = undefined
      }
    })
  }

  create() {
    const popup = new mapboxgl.Popup({ offset: 25 })
    const pin = document.createElement('div')
    pin.classList.add('location')
    pin.innerHTML = `
    <svg width="34" height="34" viewBox="0 0 34 34" fill="white" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_148_5797)">
    <path d="M24.0413 24.0418C27.9466 20.1365 27.9466 13.8049 24.0413 9.89964C20.1361 5.99439 13.8044 5.99439 9.89919 9.89964C5.99395 13.8049 5.99395 20.1365 9.89919 24.0418C13.8044 27.947 20.1361 27.947 24.0413 24.0418Z" stroke="#374650" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M16.9702 12.7281V21.2133" stroke="#374650" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12.7275 16.9707H21.2128" stroke="#374650" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
    <clipPath id="clip0_148_5797">
    <rect width="24" height="24" fill="white" transform="translate(0 16.9707) rotate(-45)"/>
    </clipPath>
    </defs>
    </svg>
    `
    popup
      .on('open', (e) => {
        location.open = true
      })
      .on('close', (e) => {
        location.open = false
      })
    const marker = new mapboxgl.Marker(pin, { draggable: true })

    return marker
  }

  toggle(lngLat: mapboxgl.LngLat) {
    this.lngLat = undefined
    if (this.marker) {
      this.marker.remove()
      this.marker = undefined
    }
    if (this.active) {
      location.close()
      router.push({ name: 'home' })
    } else {
      this.marker = this.create()
      this.marker.setLngLat(lngLat).addTo(this.map)
      this.marker.on('dragend', () => {
        this.lngLat = this.marker?.getLngLat()
        location.setLocation()
      })
      this.lngLat = lngLat
      router.push({ name: 'location' })
      // flyto center of coordinates
      this.map.flyTo({
        center: lngLat,
        zoom: 14,
        essential: true
      })
      location.open = true
    }
    this.active = !this.active
  }

  remove() {
    this.active = false
    this.lngLat = undefined
    if (this.marker) {
      this.marker.remove()
      this.marker = undefined
    }
  }

  onLongClick() {
    const startLongClickTimer = (
      e: mapboxgl.MapMouseEvent | mapboxgl.MapTouchEvent
    ) => {
      if (this.map.isDrawing) return
      const target = e.originalEvent?.target as HTMLElement
      if (!target?.classList.contains('mapboxgl-canvas')) {
        return
      }
      this.longClickTimeout = setTimeout(() => {
        this.toggle(e.lngLat)
      }, 1000)
    }
    const resetLongClick = () => {
      if (this.map.isDrawing) return
      if (this.longClickTimeout) {
        clearTimeout(this.longClickTimeout)
        this.longClickTimeout = undefined
      }
    }
    this.map.on('mousedown', startLongClickTimer)
    this.map.on('touchstart', (e: mapboxgl.MapTouchEvent) => {
      if (this.map.isDrawing) return
      if (e.points.length > 1) {
        if (this.longClickTimeout) {
          clearTimeout(this.longClickTimeout)
          this.longClickTimeout = undefined
        }
      } else {
        startLongClickTimer(e)
      }
    })

    this.map.on('dragstart', resetLongClick)
    this.map.on('dragend', resetLongClick)
    this.map.on('movestart', resetLongClick)
    this.map.on('moveend', resetLongClick)
    this.map.on('zoomstart', resetLongClick)
    this.map.on('zoomend', resetLongClick)
    this.map.on('mouseup', resetLongClick)
    this.map.on('touchend', resetLongClick)
    this.map.on('touchmove', resetLongClick)
  }
}
