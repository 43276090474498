<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { computed, ref } from 'vue'
import TtButton from '@/components/TtButton.vue'
import { useLocationStore } from '@/store/location'
import { useAccountStore } from '@/store/account'
import { storeToRefs } from 'pinia'
import TtListbox from '@/components/TtListbox.vue'
import TtAccordion from '@/components/TtAccordion.vue'
import LogoIconGray from './Logo/LogoIconGray.vue'
import IconTrash from '@/components/Icon/Trash.vue'
import { filter } from 'lodash'
import IconClose from '@/components/Icon/Close.vue'
import TtContainer from '@/components/TtContainer.vue'
import ProfilePicture from './Icon/ProfilePicture.vue'
import { id } from 'date-fns/locale'

const { t } = useI18n({ useScope: 'global' })

const locations = useLocationStore()
const account = useAccountStore()
const { location } = storeToRefs(locations)

const { reviews } = storeToRefs(locations)
const { user } = storeToRefs(account)

const comment = ref('')
const rating = ref({ id: 5, name: '5 stjärnor' })
const images = ref<any[]>([null])
const showAll = ref(false)
const uploadingComment = ref(false)

const commentLength = computed(() => {
  return comment.value.length
})

const sortedReviews = computed(() => {
  return reviews.value
    .map((r: any) => {
      return {
        ...r,
        images:
          r?.images?.map((hash: string) => {
            return {
              small: `${import.meta.env.VITE_IMAGEPROXY_URL}/75/${
                import.meta.env.VITE_MINIO_HOST
              }/public/${hash}`,
              large: `${import.meta.env.VITE_IMAGEPROXY_URL}/1280,0/${
                import.meta.env.VITE_MINIO_HOST
              }/public/${hash}`
            }
          }) ?? []
      }
    })
    .sort((a, b) => {
      return new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    })
    .slice(0, showAll.value ? -1 : 3)
})

const reviewAge = (date: string) => {
  const now = new Date()
  const reviewDate = new Date(date)
  const diff = now.getTime() - reviewDate.getTime()
  const minutes = Math.floor(diff / (1000 * 60))
  const hours = Math.floor(diff / (1000 * 60 * 60))
  const days = Math.floor(hours / 24)

  if (minutes < 1) {
    return 'För mindre än en minut sedan'
  }
  if (minutes < 60 && minutes >= 1) {
    return (
      'För ' + minutes + (minutes === 1 ? ' minut sedan' : ' minuter sedan')
    )
  }
  if (hours < 24 && hours >= 1) {
    return 'För ' + hours + (hours === 1 ? ' timme sedan' : ' timmar sedan')
  }
  if (days < 7 && days >= 1) {
    return 'För ' + days + (days === 1 ? ' dag sedan' : ' dagar sedan')
  }

  return reviewDate.toLocaleDateString('sv-SE')
}

const submit = async () => {
  if (!user.value) return

  const data = {
    comment: comment.value,
    rating: rating.value.id,
    images: images.value
  }

  uploadingComment.value = true
  await locations.addReview(data)
  await locations.fetchReviews()
  comment.value = ''
  rating.value = { id: 5, name: '5 stjärnor' }
  images.value = [null]
  uploadingComment.value = false
}

const modalOpen = ref<boolean>(false)

const selectedReviewId = ref(null)

const openRemoveReviewModal = (id: any) => {
  modalOpen.value = true
  selectedReviewId.value = id // Store the review ID
}

// remove review if user clicks on the trash icon
const removeReview = async () => {
  if (selectedReviewId.value) {
    await locations.removeReview(selectedReviewId.value)
    modalOpen.value = false
  }
}

const showTrashIcon = (review: any) => {
  // if user is logged in and the review is made by the user
  if (user.value && review.user.id === user.value.id) {
    return true
  } else {
    return false
  }
}

const changeImages = (e: any, index: number) => {
  images.value[index] = e.target?.files[0] ?? null
  if (images.value.filter((image) => image === null).length == 0) {
    images.value.push(null)
  }
}

const removeImage = (index: number) => {
  images.value = images.value.filter((_, i: number) => i !== index)
  if (images.value.length === 0) {
    images.value.push(null)
  }
}

const addImageInput = () => {
  images.value.push(null)
  const input = document.querySelector(
    `label[for="imagefile${images.value.length - 1}`
  ) as HTMLInputElement
  input?.click()
}
</script>

<template>
  <div class="mx-5 mb-5 border-b border-black/10 pb-5">
    <TtAccordion :closed="true">
      <template v-slot:accordionButton>
        <div class="flex w-full items-center justify-between gap-1.5">
          <h2 class="text-lg font-semibold leading-9 tracking-tight">
            {{ t('location.reviews') }}
          </h2>
          <div class="flex flex-1 items-center justify-between">
            <div class="flex items-center gap-1">
              <LogoIconGray class="h-3.5 w-3.5" />
              <p class="text-[0.5rem] font-semibold">Trusted Truckers</p>
            </div>
            <div class="flex items-center gap-1">
              <svg
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.63335 0.494309L4.81946 4.17209L0.761125 4.76376C0.0333466 4.86931 -0.25832 5.76654 0.269458 6.28043L3.20557 9.14154L2.51113 13.1832C2.38613 13.9138 3.15557 14.461 3.80002 14.1193L7.43058 12.211L11.0611 14.1193C11.7056 14.4582 12.475 13.9138 12.35 13.1832L11.6556 9.14154L14.5917 6.28043C15.1195 5.76654 14.8278 4.86931 14.1 4.76376L10.0417 4.17209L8.2278 0.494309C7.9028 -0.161247 6.96113 -0.169581 6.63335 0.494309Z"
                  fill="#00E1A5"
                />
              </svg>
              <p class="font-medium">{{ location?.rating ?? 0 }}</p>
            </div>
          </div>
        </div>
      </template>
      <div class="grid gap-1.5 pt-3">
        <blockquote
          v-for="review in sortedReviews"
          :class="`flex ${
            review.comment || review.images
              ? 'flex-col-reverse'
              : 'flex-row-reverse items-center'
          } gap-1.5 rounded border-black/10 bg-tt-lighter-gray px-4 ${
            review.comment ? 'pb-6 pt-4.5' : 'pb-4 pt-4'
          } `"
        >
          <div
            class="flex flex-1 items-center justify-between text-sm font-light"
          >
            <div
              :class="`flex items-center gap-1 ${
                review.comment ? 'ml-0' : 'ml-1.5'
              }`"
            >
              <button
                v-if="showTrashIcon(review)"
                @click="openRemoveReviewModal(review.id)"
                :title="t('location.remove')"
                class="relative -top-2px"
              >
                <IconTrash class="-mb-0.5" />
              </button>
              <span>{{ reviewAge(review.created_at) }}</span>
            </div>

            <cite
              class="flex items-center gap-2 pr-3 text-sm font-medium not-italic tracking-tight text-tt-gray"
            >
              <span className="whitespace-nowrap">
                {{
                  review.user.firstname +
                  (review.user.lastname &&
                    ` ${review.user.lastname.substring(0, 1)}.`)
                }}
              </span>
              <ProfilePicture
                :userId="review.user.id"
                class="h-6 w-6 rounded-full"
              />
            </cite>
          </div>
          <p
            v-if="review.comment"
            class="pb-2.5 text-base font-light leading-relaxed tracking-tight"
          >
            {{ review.comment }}
          </p>
          <div v-if="review.images" class="flex flex-wrap gap-2">
            <a
              v-for="(image, i) in review.images"
              :key="i"
              :href="image.large"
              target="_block"
            >
              <img
                :src="image.small"
                class="size-12 rounded-md object-cover"
                alt=""
                loading="lazy"
              />
            </a>
          </div>
          <div class="flex items-center justify-between">
            <div class="flex items-center gap-1.5">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="13"
                fill="none"
                viewBox="0 0 14 13"
                :class="`${
                  review.rating > 1 ? 'text-tt-green' : 'text-tt-gray/40'
                }`"
              >
                <path
                  fill="currentColor"
                  d="M6.24896557,0.452219291 L4.54018546,3.8135228 L0.717019719,4.35427888 C0.0314140581,4.45074618 -0.243351272,5.27076846 0.253843457,5.7404376 L3.01981591,8.35534564 L2.36561678,12.0492159 C2.24786019,12.7169469 2.97271327,13.2170597 3.57981918,12.9047634 L7,11.1606749 L10.4201397,12.9047634 C11.0272928,13.2145006 11.7521082,12.7169469 11.6343516,12.0492159 L10.9801901,8.35534564 L13.7461513,5.7404376 C14.2433667,5.27076846 13.9685699,4.45074618 13.2829439,4.35427888 L9.45981114,3.8135228 L7.75102162,0.452219291 C7.44485447,-0.146925325 6.55775163,-0.154542174 6.24896557,0.452219291 Z"
                />
              </svg>
              <span class="pl-0.5 text-base font-medium">{{
                review.rating
              }}</span>
            </div>
          </div>
          <TtContainer
            @set-open="(val) => (modalOpen = val)"
            variant="popup-card-bottom"
            :open="modalOpen"
          >
            <template #header>
              <div class="relative grid w-full grid-cols-10 px-5 pb-2 pt-5">
                <h2
                  class="col-span-9 text-2xl font-semibold leading-7 tracking-tight text-black"
                >
                  {{ t('location.removeReviewConfirmation') }}
                </h2>
                <button @click="modalOpen = false">
                  <IconClose class="ml-auto mr-0 h-3.5 w-3.5 text-tt-gray" />
                </button>
              </div>
              <p class="px-5 text-lg font-light tracking-tight">
                {{ t('location.noGoingBack') }}
              </p>
            </template>
            <div class="overflow-auto px-5 pb-8">
              <div class="mt-7 grid gap-4">
                <TtButton
                  @click="removeReview()"
                  size="large"
                  type="danger"
                  as="button"
                >
                  <IconTrash class="mr-1" />
                  {{ t('location.removeReview') }}
                </TtButton>
                <TtButton
                  @click="modalOpen = false"
                  size="large"
                  type="tertiary"
                  as="button"
                >
                  {{ t('location.cancel') }}
                </TtButton>
              </div>
            </div>
          </TtContainer>
        </blockquote>
        <div
          v-if="!showAll && reviews.length > 3"
          class="mt-0 flex gap-[10px] px-0"
        >
          <TtButton
            :as="'button'"
            :size="'large'"
            :type="'tertiary'"
            class=""
            @click.prevent="showAll = true"
          >
            {{ t('filter.places.show_more_reviews') }}
          </TtButton>
        </div>
        <form
          :class="`${
            sortedReviews.length && 'mt-5 border-t border-black/10 pt-5'
          }`"
          v-if="user"
          @submit.prevent="submit"
        >
          <div
            class="rounded border border-black/10 bg-transparent font-light leading-relaxed text-tt-gray transition-colors placeholder:text-tt-gray focus-within:border-tt-green focus-within:outline-none focus-within:ring-0"
          >
            <div
              style="contenteditable"
              class="relative w-full rounded px-0 py-0"
            >
              <label for="comment" class="sr-only"> Lämna en kommentar </label>
              <textarea
                rows="3"
                id="comment"
                placeholder=""
                class="form-input w-full resize-none rounded border-none px-3 pb-8 pt-3 text-base focus:outline-none focus:ring-0"
                v-model="comment"
                maxlength="300"
              ></textarea>
            </div>
            <div class="mb-2 mr-3 text-right text-[0.6rem]">
              {{ commentLength }}/300
            </div>
          </div>
          <div class="relative mt-1.5 flex gap-[10px] px-0">
            <TtListbox
              class="w-full"
              :dark="true"
              :options="[
                { id: 1, name: '1 stjärna' },
                { id: 2, name: '2 stjärnor' },
                { id: 3, name: '3 stjärnor' },
                { id: 4, name: '4 stjärnor' },
                { id: 5, name: '5 stjärnor' }
              ]"
              :defaultValue="rating"
              :optionDisplay="'name'"
              @update="(val) => (rating = val)"
            >
              <div class="flex w-full items-center justify-between">
                <div class="flex pl-0 font-normal">
                  {{ t('location.review') }}
                </div>
                <div class="flex items-center gap-2 pr-0">
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    :class="`${
                      rating.id > 1 ? 'text-tt-green' : 'text-tt-gray/40'
                    }`"
                  >
                    <path
                      d="M6.63335 0.494309L4.81946 4.17209L0.761125 4.76376C0.0333466 4.86931 -0.25832 5.76654 0.269458 6.28043L3.20557 9.14154L2.51113 13.1832C2.38613 13.9138 3.15557 14.461 3.80002 14.1193L7.43058 12.211L11.0611 14.1193C11.7056 14.4582 12.475 13.9138 12.35 13.1832L11.6556 9.14154L14.5917 6.28043C15.1195 5.76654 14.8278 4.86931 14.1 4.76376L10.0417 4.17209L8.2278 0.494309C7.9028 -0.161247 6.96113 -0.169581 6.63335 0.494309Z"
                      fill="currentColor"
                    />
                  </svg>
                  <div class="flex font-medium tabular-nums">
                    {{ rating.id }}
                  </div>
                </div>
              </div>
            </TtListbox>
          </div>
          <div class="mt-3 flex items-center justify-between gap-1.5">
            <label class="flex items-center gap-2">
              <input
                type="checkbox"
                class="form-checkbox h-4.5 w-4.5 rounded border border-tt-light-gray bg-transparent text-tt-gray transition-colors focus:border-tt-green focus:outline-none focus:ring-0"
              />
              <span class="whitespace-nowrap">{{
                t('filter.places.share_only_w_place_owner')
              }}</span>
            </label>
            <div class="flex items-center gap-2">
              <span
                class="w-28 overflow-hidden text-ellipsis whitespace-nowrap text-right font-semibold"
              >
                {{
                  user.firstname + (user.lastname ? ' ' + user.lastname : '')
                }}
                (#{{ user.id }})
              </span>
              <span>
                <ProfilePicture class="h-6 w-6 rounded-full" />
              </span>
            </div>
          </div>
          <div class="mt-1.5 flex flex-col gap-1">
            <div
              v-for="(image, index) in images"
              :key="index"
              class="relative flex items-center"
            >
              <input
                :id="'imagefile' + index"
                type="file"
                accept="image/jpg, image/jpeg, image/png"
                class="w-1 h-1 absolute -left-96 invisible"
                @change="(e) => changeImages(e, index)"
              />
              <div
                class="form-input flex w-full flex-1 justify-between rounded border border-black/10 bg-transparent px-4 py-3 text-base focus:border-tt-green"
              >
                <label :for="'imagefile' + index" class="flex-1 text-center hover:text-black cursor-pointer">
                  {{ image?.name ? image.name : t('location.addImage') }}
                </label>
                <button
                  v-if="images[index]"
                  class="bg-transparent border-none size-6 block hover:text-black cursor-pointer"
                  @click.prevent="removeImage(index)"
                >
                  &#x2715;
                </button>
              </div>
            </div>
          </div>
          <div class="mt-4">
            <TtButton
              type="tertiary"
              size="large"
              as="button"
              :disabled="commentLength < 0 || uploadingComment"
            >
              {{ uploadingComment ? t('location.loading') : t('filter.places.send_and_publish_review') }}
            </TtButton>
          </div>
        </form>
      </div>
    </TtAccordion>
  </div>
</template>
