<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2220_5959)">
      <rect width="24" height="24" rx="3" fill="#00e1a5" />
      <path
        d="M8.61328 17V7.54541H12.3434C13.0605 7.54541 13.6714 7.68237 14.1761 7.95628C14.6809 8.22711 15.0656 8.60412 15.3303 9.08731C15.598 9.56743 15.7319 10.1214 15.7319 10.7492C15.7319 11.3771 15.5965 11.9311 15.3256 12.4112C15.0548 12.8913 14.6624 13.2652 14.1484 13.533C13.6375 13.8007 13.0189 13.9346 12.2926 13.9346H9.91513V12.3327H11.9695C12.3542 12.3327 12.6712 12.2665 12.9205 12.1342C13.1728 11.9988 13.3606 11.8126 13.4837 11.5756C13.6098 11.3355 13.6729 11.0601 13.6729 10.7492C13.6729 10.4353 13.6098 10.1614 13.4837 9.92751C13.3606 9.69053 13.1728 9.50741 12.9205 9.37815C12.6681 9.24581 12.348 9.17964 11.9602 9.17964H10.6122V17H8.61328Z"
        fill="black"
      />
    </g>
  </svg>
</template>
