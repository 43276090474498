import type {
  Location,
  LocationFeatures,
  LocationFeature,
  LocationFeatureChild
} from '@/location'

import { i18n } from '@/i18n'

export function formattedAddress(location: Location) {
  if (!location?.address) return ''
  return `${location.address.street ?? ''}${
    location.address.zip || location.address.city ? ', ' : ''
  }${location.address.zip ? location.address.zip : ''}${
    location.address.zip && location.address.city ? ' ' : ''
  }${location.address.city ?? ''}${
    location.address.country ? ', ' + location.address.country : ''
  }`
}

export const locationTypes = [
  {
    label: i18n.global.t('location.types.free_parking'),
    value: 'free_parking'
  },
  {
    label: i18n.global.t('location.types.paid_parking'),
    value: 'paid_parking'
  },
  {
    label: i18n.global.t('location.types.safe_parking'),
    value: 'safe_parking'
  },
  {
    label: i18n.global.t('location.types.ranking_position'),
    value: 'ranking_position'
  },
  {
    label: i18n.global.t('location.types.repair_shop'),
    value: 'repair_shop'
  },
  {
    label: i18n.global.t('location.types.toilet'),
    value: 'toilet'
  },
  {
    label: i18n.global.t('location.types.shower'),
    value: 'shower'
  },
  {
    label: i18n.global.t('location.types.store'),
    value: 'store'
  },
  {
    label: i18n.global.t('location.types.laundry'),
    value: 'laundry'
  },
  {
    label: i18n.global.t('location.types.restaurant'),
    value: 'restaurant'
  },
  {
    label: i18n.global.t('location.types.loadingUnloading'),
    value: 'loadingUnloading'
  }
]

const openingHoursOnCheck = (
  feature: LocationFeature,
  child: LocationFeatureChild
) => {
  if (!feature.children) return
  if (child.key === '24h') {
    for (const c of feature.children) {
      if (child.key !== c.key) {
        c.checked = false
      }
    }
  } else {
    for (const c of feature.children) {
      if (c.key === '24h') {
        c.checked = false
      } else {
        c.checked = true
      }
    }
  }
}

export const features = {
  openhours: {
    name: i18n.global.t('meta.label.openhours'),
    onCheck: (feature: LocationFeature) => {
      if (feature.key === 'allweek') {
        for (const child of features.openhours.children) {
          if (child.key !== feature.key) {
            child.checked = false
          }
        }
      } else {
        for (const child of features.openhours.children) {
          if (child.key === 'allweek') {
            child.checked = false
          }
        }
      }
    },
    children: [
      {
        name: i18n.global.t('location.features.allweek'),
        key: 'allweek',
        checked: false,
        onCheck: openingHoursOnCheck,
        children: [
          { name: 'Dygnet runt', key: '24h', checked: false },
          {
            name: 'Öppnar',
            key: 'opens',
            checked: false,
            type: 'time',
            value: '06:00',
            defaultValue: '06:00'
          },
          {
            name: 'Stänger',
            key: 'closes',
            checked: false,
            type: 'time',
            value: '21:00',
            defaultValue: '21:00'
          }
        ]
      },
      {
        name: i18n.global.t('location.features.monday'),
        key: 'monday',
        checked: false,
        onCheck: openingHoursOnCheck,
        children: [
          { name: 'Dygnet runt', key: '24h', checked: false },
          {
            name: 'Öppnar',
            key: 'opens',
            checked: false,
            type: 'time',
            value: '06:00',
            defaultValue: '06:00'
          },
          {
            name: 'Stänger',
            key: 'closes',
            checked: false,
            type: 'time',
            value: '21:00',
            defaultValue: '21:00'
          }
        ]
      },
      {
        name: i18n.global.t('location.features.tuesday'),
        key: 'tuesday',
        checked: false,
        onCheck: openingHoursOnCheck,
        children: [
          { name: 'Dygnet runt', key: '24h', checked: false },
          {
            name: 'Öppnar',
            key: 'opens',
            checked: false,
            type: 'time',
            value: '06:00',
            defaultValue: '06:00'
          },
          {
            name: 'Stänger',
            key: 'closes',
            checked: false,
            type: 'time',
            value: '21:00',
            defaultValue: '21:00'
          }
        ]
      },
      {
        name: i18n.global.t('location.features.wednesday'),
        key: 'wednesday',
        checked: false,
        onCheck: openingHoursOnCheck,
        children: [
          { name: 'Dygnet runt', key: '24h', checked: false },
          {
            name: 'Öppnar',
            key: 'opens',
            checked: false,
            type: 'time',
            value: '06:00',
            defaultValue: '06:00'
          },
          {
            name: 'Stänger',
            key: 'closes',
            checked: false,
            type: 'time',
            value: '21:00',
            defaultValue: '21:00'
          }
        ]
      },
      {
        name: i18n.global.t('location.features.thursday'),
        key: 'thursday',
        checked: false,
        onCheck: openingHoursOnCheck,
        children: [
          { name: 'Dygnet runt', key: '24h', checked: false },
          {
            name: 'Öppnar',
            key: 'opens',
            checked: false,
            type: 'time',
            value: '06:00',
            defaultValue: '06:00'
          },
          {
            name: 'Stänger',
            key: 'closes',
            checked: false,
            type: 'time',
            value: '21:00',
            defaultValue: '21:00'
          }
        ]
      },
      {
        name: i18n.global.t('location.features.friday'),
        key: 'friday',
        checked: false,
        onCheck: openingHoursOnCheck,
        children: [
          { name: 'Dygnet runt', key: '24h', checked: false },
          {
            name: 'Öppnar',
            key: 'opens',
            checked: false,
            type: 'time',
            value: '06:00',
            defaultValue: '06:00'
          },
          {
            name: 'Stänger',
            key: 'closes',
            checked: false,
            type: 'time',
            value: '21:00',
            defaultValue: '21:00'
          }
        ]
      },
      {
        name: i18n.global.t('location.features.saturday'),
        key: 'saturday',
        checked: false,
        onCheck: openingHoursOnCheck,
        children: [
          { name: 'Dygnet runt', key: '24h', checked: false },
          {
            name: 'Öppnar',
            key: 'opens',
            checked: false,
            type: 'time',
            value: '06:00',
            defaultValue: '06:00'
          },
          {
            name: 'Stänger',
            key: 'closes',
            checked: false,
            type: 'time',
            value: '21:00',
            defaultValue: '21:00'
          }
        ]
      },
      {
        name: i18n.global.t('location.features.sunday'),
        key: 'sunday',
        checked: false,
        onCheck: openingHoursOnCheck,
        children: [
          { name: 'Dygnet runt', key: '24h', checked: false },
          {
            name: 'Öppnar',
            key: 'opens',
            checked: false,
            type: 'time',
            value: '06:00',
            defaultValue: '06:00'
          },
          {
            name: 'Stänger',
            key: 'closes',
            checked: false,
            type: 'time',
            value: '21:00',
            defaultValue: '21:00'
          }
        ]
      }
    ]
  },
  comforts: {
    name: i18n.global.t('filter.label.facilities'),
    children: [
      {
        name: i18n.global.t('location.features.toilet'),
        key: 'toilet',
        icon: 'toilet',
        checked: false,
        children: [
          {
            name: i18n.global.t('location.features.separate_toilet'),
            key: 'separate_toilet',
            type: 'icon',
            icon: 'separate_toilet',
            checked: false
          },
          // {
          //   name: i18n.global.t('location.features.amount_toilet_dames'),
          //   key: 'toilet-dames',
          //   type: 'amount',
          //   checked: false,
          //   value: '0',
          //   defaultValue: '0'
          // },
          // {
          //   name: i18n.global.t('location.features.amount_toilet_gents'),
          //   key: 'toilet-gents',
          //   type: 'amount',
          //   checked: false,
          //   value: '0',
          //   defaultValue: '0'
          // },
          // {
          //   name: i18n.global.t('location.features.price'),
          //   key: 'toilet-pay',
          //   type: 'price',
          //   checked: false,
          //   value: null
          // },
          {
            name: i18n.global.t('location.features.code'),
            key: 'toilet-code',
            type: 'number',
            checked: false,
            value: null
          }
        ]
      },
      {
        name: i18n.global.t('location.features.shower'),
        key: 'shower',
        checked: false,
        icon: 'shower'
      },
      { 
        name: i18n.global.t('meta.wifi'),
        key: 'wifi', 
        checked: false, 
        icon: 'wifi' 
      },
      { 
        name: i18n.global.t('meta.el'),
        key: 'el', 
        checked: false, 
        icon: 'el' 
      },
      { 
        name: i18n.global.t('meta.vatten'), 
        key: 'vatten', 
        checked: false, 
        icon: 'vatten' 
      },
      // { name: 'Soptunna', key: 'soptunna', checked: false, icon: 'soptunna' }
    ]
  },
  accessibility: {
    name: i18n.global.t('meta.label.accessibility'),
    children: [
      {
        name: i18n.global.t('location.features.truck'),
        key: 'truck-15',
        checked: true
      },
      {
        name: i18n.global.t('location.features.trailer_truck'),
        key: 'truck-22',
        checked: false
      },
      {
        name: i18n.global.t('location.features.trailer_truck'),
        key: 'truck-24',
        checked: false
      },
      {
        name: i18n.global.t('location.features.trailer_truck'),
        key: 'truck-34',
        checked: false
      }
    ]
  }
} as LocationFeatures
