<script lang="ts" setup>
import { computed, ref, useSlots, watch } from 'vue'
import DateTimePicker from './DateTimePicker.vue'

const props = withDefaults(
  defineProps<{
    id: string
    type?: string
    dark?: boolean
    style?: string
    maxlength?: number
  }>(),
  {
    type: 'text'
  }
)

const model = defineModel()
const slots = useSlots()
const hasIcon = computed(() => !!slots.icon)
const hasFocus = ref<boolean>(
  !!(
    (model.value && model.value !== null && model.value !== '') ||
    props.type === 'date'
  )
)
const textInput = (event: HTMLInputElement) => {
  if (event.value && props.type !== 'date') hasFocus.value = true
}
watch(
  () => model.value,
  (value: any) => {
    hasFocus.value = !!value
    if (
      props.maxlength &&
      props.type === 'text' &&
      value?.length > props.maxlength
    ) {
      model.value = value.slice(0, props.maxlength)
    }
  }
)
</script>

<template>
  <div :class="['relative',  type == 'time' ? 'z-1' : 'z-0']">
    <label
      :for="id"
      :class="`pointer-events-none absolute top-[15px] z-10 origin-left font-medium transition-all ${
        hasFocus && hasIcon
          ? '-translate-x-7'
          : hasFocus
          ? '-translate-x-2 '
          : ''
      } ${hasFocus && '-translate-y-4 scale-75 px-2 py-1'} ${
        hasIcon ? 'left-8' : 'left-3'
      } ${dark ? 'text-tt-gray/50' : 'text-white/50'}`"
    >
      <slot />
    </label>
    <div class="group relative">
      <component
        :is="type == 'time' ? DateTimePicker : 'input'"
        :class="`${
          style ?? ''
        } form-input w-full appearance-none rounded border bg-transparent pb-1 pt-5 text-base font-light leading-[26px] transition-colors focus:border-tt-green focus:outline-none focus:ring-0 ${
          hasIcon ? 'pl-8 pr-3' : 'px-3'
        } ${
          dark
            ? 'border-tt-gray/25 text-tt-gray placeholder:text-tt-gray'
            : 'border-tt-pale/25 text-white placeholder:text-white'
        }`"
        :type="type ?? 'text'"
        :id="id"
        v-model="model"
        @focus="hasFocus = true"
        @blur="
          ;(model === undefined || model === '' || model === null) &&
          type !== 'date'
            ? (hasFocus = false)
            : null
        "
        @input="textInput($event.target as HTMLInputElement)"
      />
      <span
        v-if="hasIcon"
        :class="`pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 pr-2 transition-transform ${
          hasFocus && 'translate-y-2'
        } ${
          dark
            ? 'text-tt-gray/40 group-focus-within:text-tt-gray'
            : 'text-white/40 group-focus-within:text-tt-light-gray'
        }`"
      >
        <slot name="icon" />
      </span>
    </div>
  </div>
</template>
