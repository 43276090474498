<script setup>
import { computed, onMounted, ref } from 'vue';
import VueTimepicker from 'vue3-timepicker'

const props = defineProps({
  modelValue: {
    type: String,
    required: true
  },
  format: {
    type: String,
    default: 'hh:mm'
  }
})

const emit = defineEmits([
  'update:modelValue',
  'focus',
  'blur',
  'input'
])

const model = computed({
  get () {
    return props.modelValue
  },
  set (value) {
    return emit('update:modelValue', value)
  }
})

const picker = ref(null)

onMounted(() => {
  const input = picker.value?.$el.querySelector('input')
  input.addEventListener('focus', (event) => {
    emit('focus', event)
  })
  input.addEventListener('blur', (event) => {
    emit('blur', event)
  })
  input.addEventListener('input', (event) => {
    emit('input', event)
  })
  input.addEventListener('change', (event) => {
    emit('input', event)
  })
})
</script>
<template>
  <VueTimepicker
    ref="picker"
    v-model="model"
    :format="format"
    placeholder=" "
    :hide-clear-button="true"
    @focus="emit('focus', $event)"
    @blur="emit('blur', $event)"
    @input="emit('input', $event)"
    @change="emit('input', { target: picker?.$el.querySelector('input') })" />
</template>

<style lang="postcss">
@import 'vue3-timepicker/dist/VueTimepicker.css';

.vue__time-picker {
  @apply w-full;
}

.vue__time-picker input.vue__time-picker-input {
  @apply focus:ring-transparent w-full border-0 bg-transparent;
}
</style>
